<template>
  <div>
    <div class="text-h4 pt-5 mb-5">Notifications</div>

    <v-row>
      <v-col
        cols="auto"
        class="ms-auto align-self-center d-flex align-items-center"
      >
        <v-btn color="secondary" height="40" class="me-2" @click="deleteAll">
          Удалить все уведомления
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:item.notification_class="{ item }">
        <div class="short">
          {{ item.notification_class }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-no-wrap">
          <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <template>
        <v-card>
          <v-card-title
            class="text-h5 text-center justify-center flex-column mb-5"
            >Удалить все уведомления<br />
          </v-card-title>

          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn color="accent" outlined @click="closeDelete">Отмена</v-btn>
            <v-btn color="accent" @click="deleteAllConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../api";

export default {
  name: "Notifications",
  components: {},
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
          align: "start",
        },
        {
          text: "Channel",
          value: "channel",
          align: "center",
        },
        {
          text: "Content",
          value: "content",
          align: "center",
        },
        {
          text: "Class",
          value: "notification_class",
          align: "center",
        },
        {
          text: "EMail",
          value: "notifiable_email",
          align: "center",
        },
        {
          text: "Действия",
          value: "actions",
          align: "end",
        },
      ],
      items: [],
      total: 0,
      editedItem: -1,
      loading: false,
      dialogDelete: false,
    };
  },
  methods: {
    async getItems() {
      this.loading = true;
      try {
        const res = await api.notifications.getItems();
        this.items = res.data.items;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async deleteItem(id) {
      try {
        await api.notifications.deleteItem(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Уведомление удалено`,
          color: "success",
          show: true,
        });

        await this.getItems();
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    deleteAll() {
      this.dialogDelete = true;
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async deleteAllConfirm() {
      try {
        await api.notifications.deleteAllItem();
        this.$store.dispatch("showSnackbar", {
          msg: `Все уведомления удалены`,
          color: "success",
          show: true,
        });

        await this.getItems();
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      } finally {
        this.dialogDelete = false;
      }
    },
  },
  created() {
    this.getItems();
  },
};
</script>

<style scoped>
.short {
  max-width: 300px;
  word-wrap: break-word;
}
</style>
